import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'primary',
        label: 'Promemoria_Richieste',
      },
      {
        color: 'success',
        label: 'Eventi_Calendario',
      },
    ],
    selectedCalendars: ['Promemoria_Richieste', 'Eventi_Calendario'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars, start, end }) {
      //console.log("actions -> fetchEvents | parametri passati vvvvv")
      //console.log(calendars)
      //console.log(calendars.join(','))
      
      return new Promise((resolve, reject) => {
        axios.get('v2/crmadmin/lista_calendario', {
          params: {
            calendars: calendars.join(','),
            start: start,
            end: end,
          }
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
      })            
    },
    addEvent(ctx, { event }) {
      let userInfo = JSON.parse(localStorage.getItem('userData'));
      
      //aggiungi ID_utente_amministratore
      event = Object.assign({}, event, {
        id_utente_admin: parseInt(userInfo.id),
        id_azienda_utente_admin: parseInt(userInfo.id_azienda),
      });

      return new Promise((resolve, reject) => {
        axios.post('v2/crmadmin/nuovo_evento', { event })
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      let userInfo = JSON.parse(localStorage.getItem('userData'));
      
      //aggiungi ID_utente_amministratore
      event = Object.assign({}, event, {
        id_utente_admin: parseInt(userInfo.id),
      });

      return new Promise((resolve, reject) => {
        axios.post('v2/crmadmin/upd_evento', { event })
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      console.log("removeEvent .........................")

      let userInfo = JSON.parse(localStorage.getItem('userData'));
      let dati = {}
      
      //aggiungi ID_utente_amministratore
      dati = Object.assign({}, dati, {
        id_cal: parseInt(id),
        id_utente_admin: parseInt(userInfo.id),
        id_azienda_utente_admin: parseInt(userInfo.id_azienda),
      });

      return new Promise((resolve, reject) => {
        axios.post('v2/crmadmin/del_evento_calen', { dati })
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
  },
}
